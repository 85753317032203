import "./styles/HomePage.css";
import AboutMe from "../components/AboutMe";

function HomePage() {
  // Sample project data

  return (
    <>
      {/* <Header /> */}
      <div className="homeContainer">
        <AboutMe />
      </div>
    </>
  );
}

export default HomePage;
